import React from 'react'
import style from './floorplan.module.css'
import spielzimmer from './spielzimmer.jpg'
import kueche from './kueche.jpg'

const FlooPlanBottom = ({ onRoomClick }) => {
  return (
    <svg className={style.floorPlan} width="380px" height="600px" viewBox="0 0 380 600" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <filter id="grayBlur">
          <feColorMatrix
            type="saturate"
            type="matrix"
            values="0 1 0 0 0
                  0 1 0 0 0
                  0 1 0 0 0
                  0 1 0 0.5 0 "
          />
          <feGaussianBlur stdDeviation="1.2" />
        </filter>
        <filter id="grayscale">
          <feColorMatrix type="saturate" values="0.1" />
        </filter>
        <clipPath id="spielzimmer">
          <rect x="0" y="0" width="160" height="131" />
        </clipPath>
        <clipPath id="kueche">
          <rect x="0" y="0" width="220" height="126" />
        </clipPath>
      </defs>

      <g id="Grundriss-unten" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Wohnzimmer" transform="translate(144.000000, 313.000000)">
          <rect id="Wohnzimmer-bg" stroke="#979797" fill="#FFFFFF" x="0.5" y="0.5" width="219" height="179" />
          <text fontFamily="AvenirNext-Regular, Avenir Next" fontSize="20" fontWeight="normal" fill="#37393B">
            <tspan x="40" y="99">
              Wohnzimmer
            </tspan>
          </text>
        </g>
        <g id="Küche" clipPath="url(#kueche)" transform="translate(144.000000, 177.000000)" onClick={() => onRoomClick('kitchen')} className={style.room}>
          <rect id="Küche-bg" stroke="#979797" x="0.5" y="0.5" width="219" height="125" />
          <image xlinkHref={kueche} preserveAspectRatio="none" width="219" height="125" />
          <text fontFamily="AvenirNext-Regular, Avenir Next" fontSize="20" fontWeight="normal" fill="#37393B">
            <tspan x="73" y="70">
              Küche
            </tspan>
          </text>
        </g>
        <g
          id="Spielzimmer"
          clipPath="url(#spielzimmer)"
          transform="translate(204.000000, 36.000000)"
          onClick={() => onRoomClick('gameroom')}
          className={style.room}
        >
          <rect id="Spielzimmer-bg" stroke="#979797" x="0.5" y="0.5" width="159" height="130" />
          <image xlinkHref={spielzimmer} preserveAspectRatio="none" width="159" height="130" />
          <text fontFamily="AvenirNext-Regular, Avenir Next" fontSize="20" fontWeight="normal" fill="#37393B">
            <tspan x="22" y="71">
              Spielzimmer
            </tspan>
          </text>
        </g>
        <g id="Waschküche" transform="translate(17.000000, 36.000000)">
          <rect id="Waschküche-bg" stroke="#979797" fill="#FFFFFF" x="0.5" y="0.5" width="115" height="135" />
          <text fontFamily="AvenirNext-Regular, Avenir Next" fontSize="20" fontWeight="normal" fill="#37393B">
            <tspan x="0" y="71">
              Waschküche
            </tspan>
          </text>
        </g>
        <g id="Vorrat" transform="translate(144.000000, 36.000000)">
          <rect id="Vorrat-bg" stroke="#979797" fill="#FFFFFF" x="0.5" y="0.5" width="54" height="130" />
          <text fontFamily="AvenirNext-Regular, Avenir Next" fontSize="18" fontWeight="normal" fill="#37393B">
            <tspan x="3" y="70">
              Vorrat
            </tspan>
          </text>
        </g>
        <g id="Flur" transform="translate(17.000000, 177.000000)">
          <rect id="Flur-bg" stroke="#979797" fill="#FFFFFF" x="0.5" y="0.5" width="115" height="227" />
          <text fontFamily="AvenirNext-Regular, Avenir Next" fontSize="20" fontWeight="normal" fill="#37393B">
            <tspan x="58" y="115">
              Flur
            </tspan>
          </text>
        </g>
        <g id="Treppe" transform="translate(17.000000, 210.000000)" fill="#FFFFFF" stroke="#000000">
          <rect id="Rectangle-13" x="0.5" y="0.5" width="38" height="9" />
          <rect id="Rectangle-13" x="0.5" y="9.5" width="38" height="9" />
          <rect id="Rectangle-13" x="0.5" y="18.5" width="38" height="9" />
          <rect id="Rectangle-13" x="0.5" y="27.5" width="38" height="9" />
          <rect id="Rectangle-13" x="0.5" y="36.5" width="38" height="9" />
          <rect id="Rectangle-13" x="0.5" y="45.5" width="38" height="9" />
          <rect id="Rectangle-13" x="0.5" y="54.5" width="38" height="9" />
          <rect id="Rectangle-13" x="0.5" y="63.5" width="38" height="9" />
          <rect id="Rectangle-13" x="0.5" y="72.5" width="38" height="9" />
          <rect id="Rectangle-13" x="0.5" y="81.5" width="38" height="9" />
          <rect id="Rectangle-13" x="0.5" y="90.5" width="38" height="9" />
          <rect id="Rectangle-13" x="0.5" y="99.5" width="38" height="9" />
          <rect id="Rectangle-13" x="0.5" y="108.5" width="38" height="9" />
          <rect id="Rectangle-13" x="0.5" y="117.5" width="38" height="9" />
          <rect id="Rectangle-13" x="0.5" y="126.5" width="38" height="9" />
          <rect id="Rectangle-13" x="0.5" y="135.5" width="38" height="9" />
          <rect id="Rectangle-13" x="0.5" y="144.5" width="38" height="9" />
        </g>
        <path
          d="M0.5,21.5 L0.5,507.5 L379.5,507.5 L379.5,21.5 L0.5,21.5 Z M203.5,35.5 L364.5,35.5 L364.5,167.5 L203.5,167.5 L203.5,35.5 Z M143.5,35.5 L199.5,35.5 L199.5,167.5 L143.5,167.5 L143.5,35.5 Z M16.5,35.5 L133.5,35.5 L133.5,172.5 L16.5,172.5 L16.5,35.5 Z M364.5,312.5 L364.5,493.5 L143.5,493.5 L143.5,312.5 L364.5,312.5 Z M133.5,414.5 L133.5,493.5 L16.5,493.5 L16.5,414.5 L133.5,414.5 Z M16.5,405.5 L16.5,176.5 L133.5,176.5 L133.5,405.5 L16.5,405.5 Z M143.5,176.5 L364.5,176.5 L364.5,303.5 L143.5,303.5 L143.5,176.5 Z M56.5,210.5 L56.5,363.5 L60.5,363.5 L60.5,210.5 L56.5,210.5 Z"
          id="Combined-Shape"
          stroke="#979797"
          fill="#D8D8D8"
        />
        <g id="Fenster" transform="translate(363.000000, 79.000000)" fill="#FFFFFF" stroke="#000000">
          <rect id="Rectangle-14" x="0.5" y="0.5" width="8" height="54" />
          <rect id="Rectangle-15" x="8.5" y="0.5" width="8" height="54" />
        </g>
        <g id="Fenster" transform="translate(363.000000, 194.000000)" fill="#FFFFFF" stroke="#000000">
          <rect id="Rectangle-14" x="0.5" y="0.5" width="8" height="92" />
          <rect id="Rectangle-15" x="8.5" y="0.5" width="8" height="92" />
        </g>
        <g id="Fenster" transform="translate(363.000000, 337.000000)" fill="#FFFFFF" stroke="#000000">
          <rect id="Rectangle-14" x="0.5" y="0.5" width="8" height="54" />
          <rect id="Rectangle-15" x="8.5" y="0.5" width="8" height="54" />
        </g>
        <g id="Fenster" transform="translate(363.000000, 428.000000)" fill="#FFFFFF" stroke="#000000">
          <rect id="Rectangle-14" x="0.5" y="0.5" width="8" height="54" />
          <rect id="Rectangle-15" x="8.5" y="0.5" width="8" height="54" />
        </g>
        <g
          id="Fenster"
          transform="translate(309.500000, 500.500000) rotate(90.000000) translate(-309.500000, -500.500000) translate(302.000000, 470.000000)"
          fill="#FFFFFF"
          stroke="#000000"
        >
          <rect id="Rectangle-14" x="0.5" y="0.5" width="7" height="60" />
          <rect id="Rectangle-15" x="7.5" y="0.5" width="7" height="60" />
        </g>
        <g
          id="Fenster"
          transform="translate(202.500000, 500.500000) rotate(90.000000) translate(-202.500000, -500.500000) translate(195.000000, 470.000000)"
          fill="#FFFFFF"
          stroke="#000000"
        >
          <rect id="Rectangle-14" x="0.5" y="0.5" width="7" height="60" />
          <rect id="Rectangle-15" x="7.5" y="0.5" width="7" height="60" />
        </g>
        <g
          id="Fenster"
          transform="translate(45.000000, 500.500000) rotate(90.000000) translate(-45.000000, -500.500000) translate(37.500000, 479.500000)"
          fill="#FFFFFF"
          stroke="#000000"
        >
          <rect id="Rectangle-14" x="0.5" y="0.5" width="7" height="41" />
          <rect id="Rectangle-15" x="7.5" y="0.5" width="7" height="41" />
        </g>
        <g
          id="Fenster"
          transform="translate(50.000000, 28.500000) rotate(90.000000) translate(-50.000000, -28.500000) translate(42.500000, 6.500000)"
          fill="#FFFFFF"
          stroke="#000000"
        >
          <rect id="Rectangle-14" x="0.5" y="0.5" width="7" height="43" />
          <rect id="Rectangle-15" x="7.5" y="0.5" width="7" height="43" />
        </g>
        <g
          id="Fenster"
          transform="translate(174.000000, 28.500000) rotate(90.000000) translate(-174.000000, -28.500000) translate(166.500000, 12.500000)"
          fill="#FFFFFF"
          stroke="#000000"
        >
          <rect id="Rectangle-14" x="0.5" y="0.5" width="7" height="31" />
          <rect id="Rectangle-15" x="7.5" y="0.5" width="7" height="31" />
        </g>
        <text id="Erdgeschoss" fontFamily="AvenirNext-Regular, Avenir Next" fontSize="48" fontWeight="normal" fill="#4A4A4A">
          <tspan x="0" y="582">
            Erdgeschoss
          </tspan>
        </text>
        <text id="Eingang" fontFamily="AvenirNext-Regular, Avenir Next" fontSize="16" fontWeight="normal" fill="#37393B">
          <tspan x="75" y="528">
            Eingang
          </tspan>
        </text>
        <text id="Eingang" fontFamily="AvenirNext-Regular, Avenir Next" fontSize="16" fontWeight="normal" fill="#37393B">
          <tspan x="75" y="16">
            Eingang
          </tspan>
        </text>
        <g id="Tür" transform="translate(155.000000, 138.000000)" fill="#FFFFFF" stroke="#000000">
          <rect id="Rectangle-12" x="1" y="28.5" width="29" height="11" />
          <path
            d="M29.9913793,28.5 L1,28.5 L1,0.539492377 C1.48811543,0.513274483 1.9796032,0.5 2.47413793,0.5 C17.6714907,0.5 29.9913793,13.036027 29.9913793,28.5 Z"
            id="Combined-Shape"
          />
        </g>
        <g
          id="Tür"
          transform="translate(97.500000, 397.000000) scale(-1, 1) translate(-97.500000, -397.000000) translate(82.000000, 377.000000)"
          fill="#FFFFFF"
          stroke="#000000"
        >
          <rect id="Rectangle-12" x="1" y="28.5" width="29" height="9" />
          <path
            d="M29.9913793,28.5 L1,28.5 L1,0.539492377 C1.48811543,0.513274483 1.9796032,0.5 2.47413793,0.5 C17.6714907,0.5 29.9913793,13.036027 29.9913793,28.5 Z"
            id="Combined-Shape"
          />
        </g>
        <g
          id="Tür"
          transform="translate(100.500000, 487.000000) scale(-1, 1) translate(-100.500000, -487.000000) translate(85.000000, 466.000000)"
          fill="#FFFFFF"
          stroke="#000000"
        >
          <rect id="Rectangle-12" x="1" y="27.5" width="29" height="14" />
          <path
            d="M29.9913793,28 L1,28 L1,0.0394923773 C1.48811543,0.0132744833 1.9796032,0 2.47413793,0 C17.6714907,0 29.9913793,12.536027 29.9913793,28 Z"
            id="Combined-Shape"
          />
        </g>
        <g
          id="Tür"
          transform="translate(258.500000, 322.000000) scale(-1, -1) translate(-258.500000, -322.000000) translate(243.000000, 302.000000)"
          fill="#FFFFFF"
          stroke="#000000"
        >
          <rect id="Rectangle-12" x="1" y="28.5" width="29" height="11" />
          <path
            d="M29.9913793,28.5 L1,28.5 L1,0.539492377 C1.48811543,0.513274483 1.9796032,0.5 2.47413793,0.5 C17.6714907,0.5 29.9913793,13.036027 29.9913793,28.5 Z"
            id="Combined-Shape"
          />
        </g>
        <g
          id="Tür"
          transform="translate(106.500000, 42.500000) scale(-1, -1) translate(-106.500000, -42.500000) translate(91.000000, 21.000000)"
          fill="#FFFFFF"
          stroke="#000000"
        >
          <rect id="Rectangle-12" x="1" y="27.5" width="29" height="15" />
          <path
            d="M29.9913793,28 L1,28 L1,0.0394923773 C1.48811543,0.0132744833 1.9796032,0 2.47413793,0 C17.6714907,0 29.9913793,12.536027 29.9913793,28 Z"
            id="Combined-Shape"
          />
        </g>
        <g id="Tür" transform="translate(239.000000, 138.000000)" fill="#FFFFFF" stroke="#000000">
          <rect id="Rectangle-12" x="1" y="28.5" width="29" height="11" />
          <path
            d="M29.9913793,28.5 L1,28.5 L1,0.539492377 C1.48811543,0.513274483 1.9796032,0.5 2.47413793,0.5 C17.6714907,0.5 29.9913793,13.036027 29.9913793,28.5 Z"
            id="Combined-Shape"
          />
        </g>
        <g id="Tür" transform="translate(75.000000, 143.000000)" fill="#FFFFFF" stroke="#000000">
          <rect id="Rectangle-12" x="1" y="27.5" width="29" height="7" />
          <path
            d="M29.9913793,28.5 L1,28.5 L1,0.539492377 C1.48811543,0.513274483 1.9796032,0.5 2.47413793,0.5 C17.6714907,0.5 29.9913793,13.036027 29.9913793,28.5 Z"
            id="Combined-Shape"
          />
        </g>
        <g
          id="Tür"
          transform="translate(153.000000, 206.500000) rotate(90.000000) translate(-153.000000, -206.500000) translate(137.500000, 186.500000)"
          fill="#FFFFFF"
          stroke="#000000"
        >
          <rect id="Rectangle-12" x="1" y="28.5" width="29" height="12" />
          <path
            d="M29.9913793,28.5 L1,28.5 L1,0.539492377 C1.48811543,0.513274483 1.9796032,0.5 2.47413793,0.5 C17.6714907,0.5 29.9913793,13.036027 29.9913793,28.5 Z"
            id="Combined-Shape"
          />
        </g>
      </g>
    </svg>
  )
}

export default FlooPlanBottom
